import { Trans, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { useState, useEffect } from 'react';

const Footer = () => {
    const { t } = useTranslation()
    const [margin, setMargin] = useState("0")

    let mc = getCookieConsentValue("acceptGaCookies")

    useEffect(() => {
        if(!mc) { setMargin("180px") }
        else { setMargin("0") }
    }, [mc])

    return (
        <footer id="site-footer" style={{ marginBottom: margin }}>
            <div className="flex-container flex-container--column">
                <div className="flex-container flex-container--column">
              
                    <a href="https://pilot-screentime.de/" target="_blank" rel="noreferrer">
                        <img src="assets/images/poweredby.png" alt="Poweredby Screentime" id="poweredby" width="165" height="60" />
                    </a>

                    <div className="flex-container address">
                        <div style={{ width: "12px", margin: "3px 5px 0 0" }}>
                            <img src="assets/images/icon_adress.png" alt="Marker Icon" width="12" height="12" />
                        </div>
                        <div>
                            Neue Rabenstraße 12 <br />
                            20354 Hamburg <br />
                            { t('footer.germany') }
                        </div>
                    </div>

                    <div className="flex-container address">
                        <div style={{ width: "12px", margin: "3px 5px 0 0" }}>
                            <img src="assets/images/icon_phone.png" alt="Telephone Icon" width="12" height="12" />
                        </div>
                        <div>
                            <a href="tel:+49403037660" className="footer-link">+49 40 303766-0</a> <br />
                        </div>
                    </div>
                </div>


                <div className="flex-container flex-container--column--sm address">
                    <Link to="/privacy" className="footer-link">{ t('footer.privacy') }</Link>
                    <Link to="/legal" className="footer-link">{ t('footer.legal') }</Link>
                    © pilot Screentime 2020
                </div>
            </div>
            <CookieConsent
                enableDeclineButton
                flipButtons
                location="bottom"
                buttonText={ t('cookieDisclaimer.acceptButton') }
                declineButtonText={ t('cookieDisclaimer.declineButton') }
                cookieName="acceptGaCookies"
                sameSite="Strict"
                style={{ background: "#ffcc00", textAlign: "center", flex: "0", flexDirection: "column", alignItems: "center" }}
                contentStyle={{ flexBasis: "0", marginBottom: "15px" }}
                buttonStyle={{ background: "#64b7d8", color: "#fff", margin: "0 0 10px", display: "flex", flexDirection: "column", fontSize: "24px" }}
                declineButtonStyle={{ background: "#ffcc00", color: "#9d9d99", fontSize: "14px", margin: "0 0 15px" }}
                expires={ 150 }
                onAccept={() => setMargin("0")}
                onDecline={() => setMargin("0")}
            >
                <Trans i18nKey="multiline" components={{ LinkTo: <Link to="/privacy" style={{ textDecoration: "underline" }}>Hier</Link> }}>
                    { t('cookieDisclaimer.textblock') }
                </Trans>
            </CookieConsent>
        </footer>
    );
}

export default Footer;