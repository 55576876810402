import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import WindowDimensions from '../../js/useWindowDimensions';
import "./ImageCarousel.css";

const ImageCarousel = () => {
    const { width } = WindowDimensions()
    let displayCount = 0

    if(width >= 1024) {
        displayCount = 3
    } else {
        displayCount = 1
    }
    

    return ( 
        <div className='image-carousel'>
            <Carousel 
                autoPlay
                interval={3000}
                infiniteLoop={true}
                transitionTime={2000}
                stopOnHover={true}
                showArrows={false} 
                showThumbs={false} 
                showStatus={false} 
                centerMode={true} 
                centerSlidePercentage={100 / displayCount}
            >
                <div>
                    <img src="assets/images/folierung_rockstar.webp" alt="Fridge Rockstar" width="254" height="580" />
                </div>
                <div>
                    <img src="assets/images/folierung_cuw.webp" alt="Fridge Coppenrath & Wiese" width="254" height="580" />
                </div>
                <div>
                    <img src="assets/images/folierung_telekom.webp" alt="Fridge Telekom" width="254" height="580" />
                </div>
                <div>
                    <img src="assets/images/folierung_radeberger.webp" alt="Fridge Radeberger" width="254" height="580" />
                </div>
                <div>
                    <img src="assets/images/folierung_hella.webp" alt="Fridge Hella" width="254" height="580" />
                </div>
            </Carousel> 
        </div>
    );
}
 
export default ImageCarousel;

