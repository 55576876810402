import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import Form from "../components/Form";
import Section from "../components/Section";
import ImageCarousel from "../components/ImageCarousel/ImageCarousel";

const Home = () => {
    const [videoSrc, setVideoSrc] = useState("")

    const { t } = useTranslation()
    const fridge_th = t('home.section_3.fridge_th', { returnObjects:true })
    const fridge_td = t('home.section_3.fridge_td', { returnObjects:true })
    const screen_th = t('home.section_3.screen_th', { returnObjects:true })
    const screen_td = t('home.section_3.screen_td', { returnObjects:true })

    const handleYoutube = () => {
        const youtubeConsent = document.querySelector(".youtube-consent")
        const youtubeVideo = document.querySelector(".video-container")
        setVideoSrc("https://www.youtube.com/embed/GPZh4lIEydM?controls=0")

        youtubeConsent.style.display = "none"
        youtubeVideo.style.display = "block"
    }



    return (
        <main>
            <img src="assets/images/00_Fridge_Header.webp" alt="Fridge header" className="images" width="2000" height="600" />

            {/* Section 1 */}
            <Section src="assets/images/01_Fridge_Hella.webp" alt="Fridge Hella">
                <h2 className="headline--blue">{ t('home.section_1.title') }</h2>
                    
                <div className="grid__row grid__row--half-width">
                    <div className="grid__col">
                        <p>
                            <Trans i18nKey="multiline" 
                            components={{ 
                                LinkTo: <a href='./#contact'>Erhalten Sie jetzt Ihr kostenloses und unverbindliches Angebot.</a> }}
                            >
                                { t('home.section_1.textblock') }
                            </Trans>
                        </p>
                    </div>
                
                    <div className="grid__col">
                        <div className="youtube-consent">
                            <div className="youtube-consent--box">
                                <p>{ t('home.section_1.video_consent') }</p>
                                <a href={t('home.section_1.video_link')} target="_blank" rel="noreferrer">{ t('home.section_1.video_link_text') }</a>
                                <button className="btn--yellow" onClick={ handleYoutube } style={{ "margin": "15px auto 0"}}>{ t('home.section_1.video_button') }</button>
                            </div>
                        </div>
                        <div className="video-container">
                            <iframe width="560" height="315" src={ videoSrc } title="Screen Fridge from Hella Mineralwasser" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </Section>


            {/* Section 2 */}
            <Section src="assets/images/02_Fridge_Radeberger.webp" alt="Fridge Radeberger">
                <h2 className="headline--yellow">{ t('home.section_2.title') }</h2>
                    
                <div className="grid__row grid__row--half-width">
                    <div className="grid__col">
                        <img src="assets/images/attributes.webp" alt="Small view of a fridge" className="images" width="508" height="650" />
                    </div>

                    <div className="grid__col">
                        <p>
                            { t('home.section_2.textblock') }
                        </p>

                        <ul>
                            {t('home.section_2.listblock', { returnObjects:true }).map((item,index) => (
                            <li key={ index }>{ item }</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Section>


            {/* Section 3 */}
            <Section src="assets/images/03_Fridge_Detail.webp" alt="Fridge detail view">
                <h2 className="headline--blue">{ t('home.section_3.title') }</h2>
                <p>
                    { t('home.section_3.textblock') }
                </p>
                   
                <div className="grid__row grid__row--half-width">
                    <div className="grid__col">
                        <table className="table-fridge">
                            <tbody>
                            <tr>
                                <th>{ t('home.section_3.fridge_headline') }</th>
                            </tr>
                            {fridge_th.map((name, value) => (
                                <tr key={ name }>
                                    <td>{ name }</td>
                                    <td>{ fridge_td[value] }</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="grid__col">
                        <table className="table-screen">
                            <tbody>
                            <tr>
                                <th>{ t('home.section_3.screen_headline') }</th>
                            </tr>
                            {screen_th.map((name, value) => (
                                <tr key={ name }>
                                    <td>{ name }</td>
                                    <td>{ screen_td[value] }</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <a href={t('home.section_3.button_link')} target="_blank" className="btn--yellow" rel="noreferrer">{ t('home.section_3.button') }</a>
                    </div>
                </div>
            </Section>


            {/* Section 4 */}
            <Section src="assets/images/04_Fridge_Cola.webp" alt="Fridge Cola">
                <h2 className="headline--yellow">{ t('home.section_4.title') }</h2>
                <div className="grid-container">
                    <div className="grid__row grid__row--one-forth">
                        {t('home.section_4.listblock', { returnObjects:true }).map((item, index) => (
                            <div key={ index } className="grid__col txt--center">
                                <img src={`assets/images/applications_${index}.png`} alt={ item } width="130" height="130" className="images--no-resize" />
                                <p>{ item }</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Section>


            {/* Section 5 */}
            <Section src="assets/images/05_Fridge_Screentime.webp" alt="Fridge Screentime">
                <h2 className="headline--blue">{ t('home.section_5.title') }</h2>
            
                <div className="grid__row grid__row--half-width">
                    <div className="grid__col">
                        <p>
                            { t('home.section_5.textblock') }
                        </p>
                    </div>
                    <div className="grid__col">
                        <a href="https://www.youtube.com/watch?v=Be5PEh858HA" rel="noreferrer" target="_blank">
                            <img src="assets/images/grafik_multiplefridges_3.webp" alt="3 transparent fridges" width="100%" height="200px" className="images" /> <br />
                            <img src="assets/images/grafik_multiplefridges_7.webp" alt="7 transparent fridges" width="100%" height="200px" className="images" />
                        </a>
                    </div>
                </div>
            </Section>


            {/* Section 6 */}
            <Section src="assets/images/06_Fridge_CuW.webp" alt="Fridge Coppenrath & Wiese">
                <h2 className="headline--yellow">{ t('home.section_6.title') }</h2>
           
                <div className="grid__row grid__row--half-width">
                    <div className="grid__col">
                        <img src="assets/images/digital_signage.webp" alt="Digital Signage" className="images" width="636" height="387" />
                    </div>

                    <div className="grid__col">
                        <p> { t('home.section_6.textblock') } </p>
                        <a href="./#contact" className="btn--yellow">{ t('home.section_6.button') }</a>
                    </div>
                </div>
            </Section>


            {/* Section 7 */}
            <Section src="assets/images/07_Fridge_Detail.webp" alt="Fridge Detail">
                <h2 className="headline--blue">{ t('home.section_7.title') }</h2>
                <p>{ t('home.section_7.textblock') }</p>
                <img src={`assets/images/${t('home.section_7.mobile_image')}`} alt="Mobile playlist" className="images images--mobile-view" width="696" height="446" />
                <img src={`assets/images/${t('home.section_7.image')}`} alt="Desktop playlist" className="images images--desktop-view" width="1296" height="432" />
            </Section>


            {/* Section 8 */}
            <Section src="assets/images/08_Fridgeforest.webp" alt="Fridgeforest">
                <h2 className="headline--yellow">{ t('home.section_8.title') }</h2>
                <p>{ t('home.section_8.textblock') }</p>
                <a href="./#contact" className="btn--yellow">{ t('home.section_8.button') }</a>
                <br />
                <ImageCarousel />
            </Section>

        
            {/* Section 9 */}
            <Form />
        </main>
    );
}

export default Home;