import { Link } from 'react-router-dom';
import { Trans, useTranslation } from "react-i18next";

const Legal = () => {
    const { t } = useTranslation()

    return (
        <div className="grid-container legal">
            <div className="grid__row">
                <div className="grid__col">
                    <h2 className="headline--blue">
                        { t('legal.headline') }
                    </h2>

                    <p>
                        <Trans i18nKey="multiline" components={{ Tel: <a href='tel:+49403037660'>+49 40303766-0</a>, Mail: <a href="mailto:info@pilot-screentime.de">info@pilot-screentime.de</a>}}>
                            { t('legal.textblock') }
                        </Trans>
                    </p>

                    <Link to="/" className="btn--yellow">{ t('legal.back_button') }</Link>
                </div>
            </div>
        </div>
    );
}

export default Legal;