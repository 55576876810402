import i18next from 'i18next';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const languages = [
        {
            code: 'de',
            name: 'Deutsch',
            country_code: 'de'
        },
        {
          code: 'en',
          name: 'English',
          country_code: 'gb'
        }
    ]

    return (
        <nav className="navbar">
            <div className="flex-container flex-container--header">
                <div className="logo">
                    <Link to="/"><img src="assets/images/logo_screenfridge.png" alt="SCREENfridge" width="320" height="130" /></Link>
                </div>

                <div className="language-selector">
                    { languages.map(({ code }) => (
                        <button key={ code } className={ `my-1 language language--${code}` } onClick={() => i18next.changeLanguage(code)}>
                        { code }
                        </button>
                    ))}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;