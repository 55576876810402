import { Link } from 'react-router-dom';
import { Trans, useTranslation } from "react-i18next";

const Privacy = () => {
    const { t } = useTranslation()

    return (
        <div className="grid-container privacy">
            <div className="grid__row">
                <div className="grid__col">

                    <h2 className="headline--blue">
                        { t('privacy.section_1.headline') }
                    </h2>

                    <p>
                        <Trans i18nKey="multiline">
                            { t('privacy.section_1.textblock') }
                        </Trans>
                    </p>

                    <h3 className="headline--yellow">
                        { t('privacy.section_2.headline') }
                    </h3>

                    <p>
                        <Trans i18nKey="multiline" components={{ Tel_1: <a href='tel:+49403037660'>+49 403037660</a>, Mailto_1: <a href="mailto:info@pilot-screentime.de">info@pilot-screentime.de</a>, Tel_2: <a href='tel:++494041400034'>+49 4041400034</a>, Mailto_2: <a href="mailto:eickmeier@unverzagt.law">eickmeier@unverzagt.law</a>}}>
                            { t('privacy.section_2.textblock') }
                        </Trans>
                    </p>

                    <h3 className="headline--yellow">
                        { t('privacy.section_3.headline') }
                    </h3>

                    <p>
                        { t('privacy.section_3.textblock_1') }
                    </p>

                    <ol>
                        {t('privacy.section_3.listblock', { returnObjects:true }).map((item,index) => (
                        <li key={ index }>{ item }</li>
                        ))}
                    </ol>

                    <p>
                        <Trans i18nKey="multiline">
                            { t('privacy.section_3.textblock_2') }
                        </Trans>
                    </p>


                    <h3 className="headline--yellow">
                        { t('privacy.section_4.headline') }
                    </h3>

                    <p>
                        { t('privacy.section_4.textblock') }
                    </p>


                    <h3 className="headline--yellow">
                        { t('privacy.section_5.headline') }
                    </h3>
                    <p>
                        <Trans i18nKey="multiline">
                            { t('privacy.section_5.textblock') }
                        </Trans>
                    </p>



                    <h3 className="headline--yellow">
                        { t('privacy.section_6.headline') }
                    </h3>

                    <p>
                        <Trans i18nKey="multiline" components={{ Link_1: <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank" rel="noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>, Link_2: <a href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank" rel="noreferrer">https://www.google.com/intl/de/policies/privacy/partners</a>, Link_3: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>}}>
                            { t('privacy.section_6.textblock') }
                        </Trans>
                    </p>


                    <Link to="/" className="btn--yellow">{ t('privacy.back_button') }</Link>
                </div>
            </div>
        </div>
    );
}

export default Privacy;