import { Trans, useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Form = () => {
    const { t } = useTranslation()
    const siteKey = "703564ce-49fc-4a85-bd77-997af3eebb52";

    const [title, setTitle] = useState("")
    const [first_name, setFirstname] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [industry, setIndustry] = useState("")
    const [telephone, setTelephone] = useState("")
    const [interest, setInterest] = useState([])
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [legal, setLegal] = useState(false)
    const [token, setToken] = useState(null)
    let [errors, setErrors] = useState([])

    const toggleCheckbox = (e) => {
        const parent = document.querySelector(`#${e.target.id}`)

        if(parent.checked === false) {
            parent.checked = true
        } else {
            parent.checked = false
        }
        
        const sib = parent.nextSibling
        const box = sib.firstChild

        box.classList.toggle('checkmark--unchecked');
    }

    const handleInputs = (e) => {
        if(e.target.name === "title") {
            setTitle(e.target.value)
        }
        if(e.target.name === "first_name") {
            setFirstname(e.target.value)
        }
        if(e.target.name === "surname") {
            setSurname(e.target.value)
        }
        if(e.target.name === "email") {
            setEmail(e.target.value)
        }
        if(e.target.name === "company") {
            setCompany(e.target.value)
        }
        if(e.target.name === "industry") {
            setIndustry(e.target.value)
        }
        if(e.target.name === "telephone") {
            const allowedValues = /^[0-9+\b]+$/;
            if (allowedValues.test(e.target.value)) {
                setTelephone(e.target.value)
            }
        }
        if(e.target.name === "interest") {
            if (e.target.checked && !interest.includes(e.target.value)) {
                setInterest([...interest, e.target.value]);
            }
            else {
                const newArr = interest.filter((item) => item !== e.target.value);
                setInterest(newArr);
            }
            toggleCheckbox(e)
        }
        if(e.target.name === "subject") {
            setSubject(e.target.value)
        }
        if(e.target.name === "message") {
            setMessage(e.target.value)
        }
        if(e.target.name === "legal") {
            if(e.target.checked) {
                setLegal(true)
            } else {
                setLegal(false)
            }
            toggleCheckbox(e)
        }
    }

    const validate = (first_name, surname, email, telephone, subject, message, legal, token) => {
        errors = []
        setErrors([errors])

        if (first_name.length === 0) {
            errors.push({ first_name: t('errors.first_name') });
        }
        if (surname.length === 0) {
            errors.push({ surname: t('errors.surname') });
        }
        if (email.length === 0) {
            errors.push({ email: t('errors.email.0') });
        }
        if (email.split("").filter(x => x === "@").length !== 1) {
            errors.push({ email: t('errors.email.1')});
        }
        if (email.indexOf(".") === -1) {
            errors.push({ email: t('errors.email.2')});
        }
        if (telephone.length === 0) {
            errors.push({ telephone: t('errors.telephone')});
        }
        if (subject.length === 0) {
            errors.push({ subject: t('errors.subject') });
        }
        if (message.length === 0) {
            errors.push({ message: t('errors.message') });
        }
        if (legal === false) {
            errors.push({ legal: t('errors.legal') });
        }
        if(!token) {
            errors.push({ token: t('errors.token') });
        }

        if (errors.length > 0) {
            setErrors([...errors])
        }
        return errors
    }

    const outputErrors = (fieldValue) => {
        return errors.filter(error => error[fieldValue]).map(error => <p key={ error[fieldValue] } className="form--error">{ error[fieldValue] }</p>)
    }

    const formSubmit = (e) => {
        e.preventDefault();

        // let data = {
        //     title: title,
        //     first_name: first_name,
        //     surname: surname,
        //     email: email,
        //     company: company,
        //     industry: industry,
        //     telephone: telephone,
        //     interest: interest,
        //     subject: subject,
        //     message: message,
        // }

        const err = validate(first_name, surname, email, telephone, subject, message, legal, token);

        if(err.length === 0 && token) {
            axios.post('https://screenfridge.pilot-screentime.de/api/index.php', {
            //axios.post('http://localhost:4000/', {
                title: title,
                first_name: first_name,
                surname: surname,
                email: email,
                company: company,
                industry: industry,
                telephone: telephone,
                interest: interest,
                subject: subject,
                message: message
            })
            .then(res => {
                resetForm()
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const resetForm = () => {
        setTitle('')
        setFirstname('')
        setSurname('')
        setEmail('')
        setCompany('')
        setIndustry('')
        setTelephone('')
        setInterest([])
        setSubject('')
        setMessage('')
        setLegal(false)
        setErrors([])
        setToken(null)

        const elementList = document.querySelectorAll('.checkmark--checked')
        elementList.forEach(element => { element.classList.add('checkmark--unchecked') });
    }

    useEffect(() => {}, [token]);

    return (
        <div className="grid-container" id="contact">
            <div className="grid__row">
                <div className="grid__col">
                    <h2 className="headline--blue">{ t('home.section_9.title') }</h2>
                    <p>
                        { t('home.section_9.textblock') }
                    </p>
                </div>
            </div>


            <form onSubmit={ formSubmit } name="contactForm">
                <div className="grid__row grid__row--one-third">
                    <div className="grid__col">
                        <label htmlFor="title">{ t('home.section_9.form.title') }</label><br/>
                        <select value={ title } name="title" id="title" onChange={ handleInputs }>
                            { t('home.section_9.form.title_option', { returnObjects:true }).map((item, index) => (
                                <option key={ index }>{ item }</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="grid__row grid__row--one-third">
                    <div className="grid__col">
                        <label htmlFor="first_name">{ t('home.section_9.form.first_name') }</label><br/>
                        <input type="text" name="first_name" id="first_name" value={ first_name } onChange={ handleInputs } autoComplete="true" />
                        { outputErrors("first_name") }
                    </div>

                    <div className="grid__col">
                        <label htmlFor="surname">{ t('home.section_9.form.surname') }</label><br/>
                        <input type="text" name="surname" id="surname" value={ surname } onChange={ handleInputs } />
                        { outputErrors("surname") }
                    </div>

                    <div className="grid__col">
                        <label htmlFor="email">{ t('home.section_9.form.email') }</label><br/>
                        <input type="email" name="email" id="email" value={ email } onChange={ handleInputs } autoComplete="true" />
                        { outputErrors("email") }
                    </div>
                </div>

                <div className="grid__row grid__row--one-third">
                    <div className="grid__col">
                        <label htmlFor="company">{ t('home.section_9.form.company') }</label><br/>
                        <input type="text" name="company" id="company" value={ company } onChange={ handleInputs } autoComplete="true" />
                    </div>

                    <div className="grid__col">
                        <label htmlFor="industry">{ t('home.section_9.form.industry') }</label><br/>
                        <input type="text" name="industry" id="industry" value={ industry } onChange={ handleInputs } />
                    </div>

                    <div className="grid__col">
                        <label htmlFor="telephone">{ t('home.section_9.form.telephone') }</label><br/>
                        <input type="tel" name="telephone" id="telephone" value={ telephone } onChange={ handleInputs } />
                        { outputErrors("telephone") }
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <p style={{ "fontSize": "1.3em" }}>{ t('home.section_9.form.interest') }</p>
                        { t('home.section_9.form.interest_options', { returnObjects:true }).map((item, index) => (
                            <label className="container-left" key={ index } htmlFor={ item }>
                                { item }
                                <input type="checkbox" id={ item } name="interest" value={ item } onChange={ handleInputs } />
                                <span className="checkmark">
                                    <span className="checkmark--checked checkmark--unchecked"></span>
                                </span>
                            </label>
                        ))}
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <label htmlFor="subject">{ t('home.section_9.form.subject') }</label><br/>
                        <input type="text" name="subject" id="subject" value={ subject } onChange={ handleInputs } />
                        { outputErrors("subject") }
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <label htmlFor="message">{ t('home.section_9.form.message') }</label><br/>
                        <textarea value={ message } name="message" id="message" onChange={ handleInputs } ></textarea>
                        { outputErrors("message") }
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <label className="container-left" htmlFor="legal">
                            <input type="checkbox" id="legal" name="legal" onChange={ handleInputs }/>
                            <span className="checkmark">
                                <span className="checkmark--checked checkmark--unchecked"></span>
                            </span>
                            { t('home.section_9.form.legal') }
                        </label>
                        { outputErrors("legal") }
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <small>{ t('home.section_9.form.small') }</small>
                    </div>
                </div>

                <div className="grid__row">
                    <div className="grid__col">
                        <HCaptcha
                            sitekey={siteKey}
                            onVerify={setToken}
                            onExpire={() => setToken(null)}
                        />
                        { outputErrors("token") }
                    </div>
                </div>
                <div className="grid__row">
                    <div className="grid__col">
                        <input type="submit" value={ t('home.section_9.form.button') } className="btn--yellow" />
                    </div>
                </div>
            </form>

            <div className="grid__row">
                <div className="grid__col">
                    <Trans i18nKey="multiline" components={{ privacyLink: <Link to="/privacy">Datenschutzbestimmungen</Link>, mailTo: <a href='mailto:info@pilot-screentime.de'>info@pilot-screentime.de</a> }}>
                        { t('home.section_9.legal') }
                    </Trans>
                </div>
            </div>
        </div>
    );
}

export default Form;