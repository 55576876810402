import { Parallax } from 'react-parallax';

const Sections = (props) => {
    return (
        <>
            <div className="grid-container">
                <div className="grid__row">
                    <div className="grid__col">
                        { props.children }
                    </div>
                </div>
            </div>
            <Parallax bgImage={props.src} bgImageAlt={props.alt} strength={200} width="2000" height="1125">
                <div style={{ height: 700 }}></div>
            </Parallax>
        </>
        
    );
}
 
export default Sections;