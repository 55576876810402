import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import './index.css';
import App from './App';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    supportedLngs: ['de', 'en'],
    fallbackLng: 'de',
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: 'assets/locales/{{lng}}/translation.json',
    }
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading..</h2>
  </div>
)

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Suspense fallback={ loadingMarkup }>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);
