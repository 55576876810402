import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { getCookieConsentValue } from "react-cookie-consent";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Legal from "./pages/Legal";

function App() {
  const cookie = getCookieConsentValue("acceptGaCookies")

  useEffect(() => {
    if(cookie === "true") {
      ReactGA.initialize('G-SV1QDLG7WY');
    }
  }, [cookie]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router basename="/">
      <div className="App">
        <Navbar />
        <div className="app-content">
          <Routes>
            <Route path="/" element={ <Home /> } exact />
            <Route path="/privacy" element={ <Privacy /> } exact />
            <Route path="/legal" element={ <Legal /> } exact />
          </Routes>
        </div>
        <div className="box box--blue"></div>
        <div className="box box--yellow"></div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;